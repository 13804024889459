@import 'vendor/normalize';
@import 'vendor/reset';

@import url('https://fonts.googleapis.com/css2?family=Barrio&display=swap');

body {
    //background-color: #ed5d79;
    background-color: #c6ccfc;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10001;
    font-family: sans-serif;
    padding: 10px;
    opacity: .75;
    p {
        font-size: 10px;
        text-transform: uppercase;
        color: #fff;
    }
    a {
        text-decoration: none;
        color: #fff;
    }
}
.app-wrap {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.bg {
    height: 200%;
    width: 200%;
    position: absolute;
    top: -50%;
    left: -50%;
    background-image: url("../images/bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    animation: bg 10s infinite ease;
}

@keyframes bg {
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(15deg) scale(1.1);
    }
}

.dolphin {
    background-image: url("../images/dolphin.webp");
    background-size: contain;
    background-repeat: no-repeat;
    height: 20vw;
    width: 20vw;
    transform: rotate(-180deg);
    animation: dolphin 5s infinite linear;
}

.dolphin-track {
    z-index: 1;
    bottom: 30%;
    left: 10%;
    position: absolute;
    animation: track 5s infinite ease;
    transform: translateY(0) rotate(-25deg);
    &.alt {
        animation: track 5s infinite ease 1s;
        left: 15%;
        .dolphin {
            animation: dolphin 5s infinite linear 1s;
        }
    }
}
@keyframes track {
    0%, 100% {
        transform: translateY(0) rotate(-25deg);
    }
    60% {
        transform: translateY(-100%);
    }
}
@keyframes dolphin {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

.sand {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-image: url('../images/sand.webp');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 3;
    .tree {
        position: absolute;
        bottom: 50px;
        left: 20%;
        width: 180px;
        height: 320px;
        background-image: url('../images/trunk.webp');
        background-size: contain;
        background-position: center bottom;
        background-repeat: no-repeat;
        z-index: 8;

        .leaves {
            position: absolute;
            top: -200px;
            left: -130px;
            width: 500px;
            height: 500px;
            background-image: url('../images/leaves.webp');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 3;
            animation: petals-1 30s infinite linear;
        }

        &.right {
            transform: scaleX(-1) scale(.8);
            left: initial;
            right: 18%;
            bottom: 80px;
            z-index: 15;
        }
    }

}
.flowers {
     //background-color: red;
     position: absolute;
     right: 10%;
     bottom: -100px;
     height: 300px;
     width: 300px;
     background-image: url('../images/stems-1.webp');
     background-repeat: no-repeat;
     background-size: contain;
     z-index: 50;
     animation: flowers 7s infinite linear;
     .petals-1 {
         position: absolute;
         top: 90px;
         left: 50px;
         width: 120px;
         height: 100px;
         background-image: url('../images/petals-1.webp');
         background-repeat: no-repeat;
         background-size: contain;
         background-position: center;
         animation: petals-1 4s infinite;
     }
     .petals-2 {
         position: absolute;
         top: 40px;
         left: 150px;
         width: 120px;
         height: 100px;
         background-image: url('../images/petals-2.webp');
         background-repeat: no-repeat;
         background-size: contain;
         background-position: center;
         animation: petals-2 4s infinite .333s;
     }

     &.reverse {
         transform: scaleX(-1) scale(.75);
         right: initial;
         left: 5%;
         bottom: -120px;
         animation: flowers-2 7s infinite linear .333s;
     }
 }
.heart-path {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    z-index: 3;
    overflow: hidden;
}
.heart-wrapper {
    position: relative;
    display: inline-block;
    animation: heart-path 60s infinite linear;
}
.heart {
    width: 200px;
    height: 200px;
    background-image: url('../images/heart.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 3;
    position: relative;
    animation: heart .8s infinite linear;
}

.clouds {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/clouds.webp');
    &.hidden {
        background-image: none;
    }
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .bird-1, .bird-2, .bird-3 {
        height: 100px;
        width: 100px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 10;
        &.left {
            z-index: 2;
        }
    }

    .bird-1 {
        background-image: url('../images/bird-1.webp');
        top: 30%;
        right: 10%;
        animation: bird-1 10s infinite;
        &.left {
            top: 30%;
            right: initial;
            left: 45%
        }
    }
    .bird-2 {
        background-image: url('../images/bird-2.webp');
        top: 30%;
        right: 20%;
        animation: bird-2 8s infinite;
        &.left {
            top: 25%;
            right: initial;
            left: 25%
        }
    }
    .bird-3 {
        background-image: url('../images/bird-3.webp');
        top: 35%;
        right: 15%;
        animation: bird-3 5s infinite;
        &.left {
            top: 28%;
            right: initial;
            left: 5%;
        }
    }
}

.sun-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 45vw;
    overflow: hidden;
}
.sun {
    width: 45vw;
    height: 45vw;
    background-image: url('../images/sun.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    animation: sun 180s linear infinite;
}

.ocean-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    overflow: hidden;
    z-index: 2;
}
.ocean {
    top: 50%;
    left: 50%;
    width: 120%;
    height: 45%;
    overflow: hidden;
    background-color: #8db3da;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateX(-50%);
    z-index: 1;
    animation: ocean 20s ease infinite;
    position: relative;
    .reflection {
        position: absolute;
        top: 0;
        right: 13vw;
        width: 35vw;
        height: 35vw;
        background-image: url('../images/reflection.webp');
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        z-index: 1;
        animation: reflection 3s ease infinite;
        &.stage-2 {
            animation: reflection 3s ease infinite 1s;
            background-image: url('../images/reflection-2.webp');
        }
        &.stage-3 {
            animation: reflection 3s ease infinite 2s;
            background-image: url('../images/reflection-3.webp');
        }
    }
    .waves {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120%;
        height: 120%;
        background-image: url('../images/waves.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateX(-50%) translateY(-50%);
        animation: waves 20s ease infinite;
    }
}
.text-logo {
    text-align: center;
    color: #ffc141;
    height: 25vw;
    width: 25vw;
    background-image: url("../images/text-logo.webp");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    animation: text 4s ease infinite;
}


.modal {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    z-index: 100;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    .form {
        display: grid;
        grid-template-columns: 2fr 80px;
        grid-gap: 5px;
    }
    h1 {
        font-family: 'Barrio', cursive;
        color: #fff;
        text-shadow: 0 2px 2px rgb(194, 48, 72);
        font-size: 64px;
        font-weight: bold;
        margin-bottom: 30px;
    }
    .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        &:not(:first-child) {
            margin-top: 15px;
        }
    }
    .button {
        background-color: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(20px);
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        width: 100%;
        border-radius: 12px;
        padding: 12px;
        height: 50px;
        //border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        text-shadow: 0 1px 1px rgb(194, 48, 72);
        outline: none;
        font-size: 16px;
        font-family: sans-serif;
        text-transform: uppercase;
        font-weight: 800;
        color: #fff;
        cursor: pointer;
        transition: box-shadow 200ms ease;
        vertical-align: middle;
        &:hover {
            box-shadow: 0 10px 20px rgba(194, 48, 72, 0.25);
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    .input {
        box-sizing: border-box;
        display: inline-block;
        text-decoration: none;
        width: 100%;
        border-radius: 12px;
        padding: 12px;
        background-color: rgba(255, 255, 255, .3);
        text-shadow: 0 1px 1px rgb(194, 48, 72);
        outline: none;
        border: none;
        font-size: 16px;
        font-family: sans-serif;
        text-transform: uppercase;
        font-weight: 800;
        color: #fff;
        &::placeholder {
            color: #fff;
        }
    }

    &.blue {
        top: 40%;
        transform: translateY(-50%);
        background-color: transparent;
        backdrop-filter: none;
        border-radius: 30px;

        .input, .button {
            text-shadow: 0 1px 1px rgb(48, 143, 194);
            background-color: rgba(148, 136, 217, 0.23);
            //background-color: rgba(0,0,0, .1);
            backdrop-filter: blur(20px);
        }
        h1 {
            display: none;
        }
    }
}
.logo {
    position: absolute;
    top: 30px;
    left: 50%;
    width: 40vw;
    height: 30vw;
    transform: translateX(-50%);
    background-image: url('../images/text.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: text 4s ease infinite;
}
@keyframes text {
    0%, 100% {
        transform: translateX(-50%);
    }
    50% {
        transform: translateX(-50%) scale(1.2) rotate(5deg);
    }
}

.thank-you {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    .socials {
        display: flex;
        align-items: center;
        flex-direction: row;
        grid-gap: 10px;
        .social {
            text-decoration: none;
            transition: transform 200ms ease;
            &:hover {
                transform: scale(1.05);
            }
        }
    }
    h1 {
        font-family: 'Barrio', cursive;
        color: #fff;
        text-shadow: 0 2px 2px rgb(194, 48, 72);
        font-size: 56px;
        font-weight: bold;
        margin-bottom: 30px;
        text-transform: lowercase;
        text-align: center;
    }
    p {
        font-family: 'Barrio', cursive;
        color: #fff;
        text-shadow: 0 2px 2px rgb(194, 48, 72);
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
        text-transform: uppercase;
        text-align: center;
        &.small {
            font-size: 14px;
            margin-top: -10px;
        }
    }
    background-color: rgba(232, 149, 160, 0.5);
    backdrop-filter: blur(20px);
    opacity: 0;
    pointer-events: none;
    &.shown {
        opacity: 1;
        pointer-events: initial;
    }
}
//

@keyframes ocean {
    0%, 100% {
        transform: translateY(0) translateX(-50%);
    }
    50% {
        transform: translateY(-15px) translateX(-50%);
    }
    60% {
        transform: translateY(-5px) translateX(-50%);
    }
    70% {
        transform: translateY(-25px) translateX(-50%);
    }
}

@keyframes waves {
    0%, 100% {
        transform: translateX(-50%) translateY(-50%);
    }
    50% {
        transform: translateX(-48%) translateY(calc(-50% + 20px));
    }
    60% {
        transform: translateX(-50%) translateY(calc(-50% + 10px));
    }
    70% {
        transform: translateX(-45%) translateY(calc(-50% + 30px));
    }
}
@keyframes flowers {
    0%, 100% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(-30%);
    }
}
@keyframes flowers-2 {
    0%, 100% {
        transform: translateY(0%) scaleX(-1) scale(.75);
    }
    50% {
        transform: translateY(-30%) scaleX(-1) scale(.75);
    }
}
@keyframes petals-1 {
    0%, 100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(-13deg);
    }

}

@keyframes petals-2 {
    0%, 100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(10deg);
    }

}

@keyframes bird-1 {
    0%, 100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(-10deg) translateX(-20px)  translateY(10px);
    }
}

@keyframes bird-2 {
    0%, 100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(5deg) translateX(-10px)  translateY(-15px);
    }
}

@keyframes bird-3 {
    0%, 100% {
        transform: rotate(0);
    }
    50% {
        transform: translateX(-20px)  translateY(20px);
    }
}

@keyframes sun {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes reflection {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes heart-path {
    0% {
        transform: scaleX(1);
        left: 0;
    }
    50.5% {
        transform: scaleX(1);
        left: 80%;
    }
    51% {
        transform: scaleX(-1);
        left: 80%;
    }
    99.5% {
        transform: scaleX(-1);
        left: 0;
    }
    100% {
        transform: scaleX(1);
        left: 0;
    }
}

@keyframes heart {
    0%, 100% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
}

@media (max-width: 800px) {
    .flowers {
        bottom: -140px;
    }
    .sand .tree {
        left: 10%;
    }
    .modal h1 {
        font-size: 48px;
    }
}

@media (max-width: 600px) {
    .dolphin-track {
        bottom: 40%;
    }
    .sun-wrapper {
        width: 65vw;
        .sun {
            width: 65vw;
            height: 65vw;
        }
    }
    .reflection {
        width: 55vw !important;
        height: 55vw !important;
        right: 0;
    }
    .flowers {
        height: 200px;
        width: 200px;
        bottom: -100px;
        .petals-1, .petals-2 {
            top: 80px;
            left: 45px;
            width: 60px;
            height: 50px;
        }
        .petals-2 {
            top: 40px;
            left: 112px;
        }
    }
    .sand {
        .tree {
            width: 120px;
            height: 190px;
            bottom: 130px;
            .leaves {
                top: -100px;
                left: -70px;
                width: 300px;
                height: 200px;
            }
        }
    }
    .clouds {
        .bird-1, .bird-2, .bird-3 {
            top: 50%;
            width: 35px;
            height: 35px;
        }
        .bird-1 {
            right: 40px;
            top: 40%;
        }
        .bird-2:not(.left) {
            display: none;
        }
        .bird-1.left {
            display: none;
        }
    }
    .modal h1 {
        font-size: 36px;
    }
    .heart {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 470px) {
    .modal .buttons, .modal .form {
        //grid-template-columns: 1fr;
    }
    .modal h1 {
        font-size: 24px;
    }
    .sand {
        background-size: 100% 100px;
        background-position: bottom center;
        .tree {
            width: 90px;
            height: 120px;
            bottom: 60px;
            .leaves {
                top: -100px;
                left: -50px;
                width: 230px;
            }
            &.right {
                bottom: 40px;
            }
        }
    }
    .heart {
        width: 75px;
        height: 75px;
    }
    .flowers {
        height: 120px;
        width: 120px;
        bottom: -50px;

        .petals-1, .petals-2 {
            top: 45px;
            left: 25px;
            width: 40px;
            height: 30px;
        }

        .petals-2 {
            top: 25px;
            left: 65px;
        }

        &.reverse {
            bottom: -50px;
        }
    }
    .thank-you {
        h1 {
            font-size: 36px;
        }
    }
}

@media (min-width: 1920px) {
    .sand {
        .tree {
            bottom: 2vw;
            left: 20vw;
            width: 15vw;
            height: 50vw;

            .leaves {
                top: 1vw;
                left: -15vw;
                width: 50vw;
                height: 50vw;
            }
            &.right {
                bottom: 10%;
            }
        }
    }
}
